import React from 'react';
import { graphql, Link } from 'gatsby';
import GoogleMap from '../components/utility/GoogleMap';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import Mailchimp from 'react-mailchimp-form';

const TextContainer = styled.div`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '655px')};

  margin: ${(props) => props.margin || '0 auto 60px auto'};

  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  @media (max-width: 767px) {
    margin: ${(props) => props.mobileMargin || '40px auto'};
  }

  & p:first-of-type {
    margin-top: 14px;
  }

  & h2 {
    @media (max-width: 767px) {
      font-size: 27px;
    }

    @media (max-width: 420px) {
      font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    }
  }
  & a {
    color: red;
    text-decoration: none;
  }
  button[disabled] {
    display: none;
  }
  .mailchimp .msg-alert * {
    margin-top: 0;
  }
`;

const MapContainer = styled.div`
  margin: 120px auto 0 auto;

  & h2 {
    margin: 0 0 19px 0;
  }

  max-width: 1390px;

  @media (max-width: 767px) {
    margin: 40px auto 0 auto;
  }
`;

const Credits = styled.div`
  & a {
    text-decoration: none;
    color: #000;
  }
`;

const Socials = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin: ${(props) => props.mobileMargin || '40px auto'};
  }

  & a {
    text-decoration: none;
    color: #000;
  }
`;

const Information = ({ data }) => (
  <>
    <Helmet title={`Information - Askeaton Contemporary Arts`} />

    <Col col={12}>
      <TextContainer
        dangerouslySetInnerHTML={{
          __html: data.prismicInformation.data.text.html,
        }}
        mobileMargin={`0 auto 40px auto`}
      />
    </Col>

    <Col col={12} lg={4}>
      <TextContainer
        margin={`60px auto 0 auto`}
        mobileMargin={`40px auto 0 auto`}
        dangerouslySetInnerHTML={{
          __html: data.prismicInformation.data.people_text.html,
        }}
      />
    </Col>

    <Col col={12} lg={4}>
      <TextContainer
        margin={`60px auto 0 auto`}
        mobileMargin={`40px auto 0 auto`}
        dangerouslySetInnerHTML={{
          __html: data.prismicInformation.data.contact_text.html,
        }}
      />
    </Col>

    <Col col={12} lg={4}>
      <TextContainer
        margin={`60px auto 0 auto`}
        mobileMargin={`40px auto`}
        dangerouslySetInnerHTML={{
          __html:
            data.prismicInformation.data.sponsors_and_supporters_text.html,
        }}
      />
    </Col>

    <Col col={12}>
      <MapContainer>
        <h2>Visit</h2>
        <GoogleMap />
      </MapContainer>
    </Col>

    <Col col={12} md={6}>
      <TextContainer
        margin={`40px 0 0 auto`}
        mobileMargin={`40px auto 0 auto`}
        dangerouslySetInnerHTML={{
          __html: data.prismicInformation.data.directions_text.html,
        }}
      />
    </Col>
    <Col col={12} md={6}>
      <TextContainer
        margin={`40px auto 0 0`}
        mobileMargin={`40px auto 0 auto`}
        dangerouslySetInnerHTML={{
          __html: data.prismicInformation.data.accomodation_text.html,
        }}
      />
    </Col>

    <Col col={12}>
      <Socials margin={`40px auto 0 0`} mobileMargin={`40px auto 0 auto`}>
        <p style={{ margin: `0` }}>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Instagram
          </a>
        </p>
        <p style={{ margin: `0` }}>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Twitter
          </a>
        </p>

        <p style={{ margin: `0` }}>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Facebook
          </a>
        </p>
      </Socials>
    </Col>
    <Col col={12} md={6} className="mobile-signup">
      <TextContainer margin={`80px 0 40px 0`} mobileMargin={`40px 0 0 0`}>
        <p style={{ margin: `0 0 1rem 0` }}>Sign up for our newsletter</p>
        <Mailchimp
          action="https://michelehorrigan.us3.list-manage.com/subscribe/post?u=2a65be56bdd8caa88eaf80d7e&amp;id=9767901f5f"
          fields={[
            {
              name: 'FNAME',
              placeholder: 'First name',
              type: 'text',
              required: true,
            },
            {
              name: 'LNAME',
              placeholder: 'Surname',
              type: 'text',
              required: true,
            },
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
            },
          ]}
          messages={{
            sending: 'Subscribing...',
            success: 'Thank you for subscribing!',
            error: 'An unexpected internal error has occurred',
            empty: 'Please fill out the fields',
            duplicate: 'You are already subscribed',
            button: 'Submit',
          }}
          className="mailchimp"
        />
      </TextContainer>
    </Col>
    <Col col={12} md={6}>
      <TextContainer margin={`80px 0 40px 0`} mobileMargin={`40px 0 40px 0`}>
        <Credits>
          <p>Website credits</p>
          <p style={{ margin: `0` }}>
            Design:{' '}
            <a
              href="https://www.daly-lyon.co.uk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Daly & Lyon
            </a>
          </p>
          <p style={{ margin: `0` }}>
            Development:{' '}
            <a
              href="https://www.kieranstartup.co.uk/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Kieran Startup
            </a>
          </p>
          <TextContainer margin={`6.75rem 0 0 0`} mobileMargin={`0 0 0 0`}>
            <Link to={`/privacy`}>Privacy Policy</Link>
          </TextContainer>
        </Credits>
      </TextContainer>
    </Col>
    <Col col={12} md={6} className="desktop-signup">
      <TextContainer margin={`80px 0 40px 0`} mobileMargin={`40px 0 40px 0`}>
        <p style={{ margin: `0 0 1rem 0` }}>Sign-up for our newsletter</p>
        <Mailchimp
          action="https://michelehorrigan.us3.list-manage.com/subscribe/post?u=2a65be56bdd8caa88eaf80d7e&amp;id=9767901f5f"
          fields={[
            {
              name: 'FNAME',
              placeholder: 'First name',
              type: 'text',
              required: true,
            },
            {
              name: 'LNAME',
              placeholder: 'Surname',
              type: 'text',
              required: true,
            },
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
            },
          ]}
          messages={{
            sending: 'Subscribing...',
            success: 'Thank you for subscribing!',
            error: 'An unexpected internal error has occurred',
            empty: 'Please fill out the fields',
            duplicate: 'You are already subscribed',
            button: 'Submit',
          }}
          className="mailchimp"
        />
      </TextContainer>
    </Col>
  </>
);

export default Information;

export const pageQuery = graphql`
  query Information {
    site {
      siteMetadata {
        footerLinks {
          name
          link
        }
      }
    }
    prismicInformation {
      data {
        text {
          html
        }
        people_text {
          html
        }
        sponsors_and_supporters_text {
          html
        }
        contact_text {
          html
        }
        directions_text {
          html
        }
        accomodation_text {
          html
        }
      }
    }
  }
`;
